import React, { useEffect } from 'react';
import { handleAuthentication } from 'utils/auth';
import { useQueryParam, StringParam } from 'use-query-params';
import { navigate } from 'gatsby';
import EventAnimation from 'components/EventAnimation';

const Callback = () => {
  handleAuthentication();
  const [r, setR] = useQueryParam('r', StringParam);
  useEffect(() => {
    navigate(r || '/');
  }, []);

  return (
    <div className="flex-1 h-screen w-screen justify-center items-center pt-40">
      <EventAnimation />
    </div>
  );
};

export default Callback;
