import React from 'react';
import tw from 'twin.macro';

const Wrapper = tw.div`bg-gradient-to-b  flex items-center justify-center w-full`;
const Inner = tw.div`bg-white p-4 rounded-md`;
const Pulse = tw.div`w-64 h-44 bg-gray-200 animate-pulse`;
const Content = tw.div`mt-8 h-32 w-full space-y-3`;
const Item = tw.div`w-20 h-6 bg-gray-200 rounded-full animate-pulse`;

export default function EventAnimationn() {
  return (
    <Wrapper>
      <Inner>
        <Pulse />
        <Content>
          <Item />
          <Item />
          <Item />
          <div className="w-full h-4 bg-gray-200 rounded-full animate-pulse" />
          <div className="w-full h-4 bg-gray-200 rounded-full animate-pulse" />
          <div className="w-1/2 h-4 bg-gray-200 rounded-full animate-pulse" />
        </Content>
      </Inner>
    </Wrapper>
  );
}
